body { background-color: #ffffff; }
body { color: var(--clr-3639); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 38px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 28px;

}
}
h3 {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h4 {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-3639);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
.me-Quote .quote-author {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-3640);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-3640);}
a:hover {color: var(--clr-3640);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-3640);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-3640);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-3640);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
a.MEC4 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-3639);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-3639);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-3639);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-3641);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-3641);
color: #ffffff;
 }
.MES8 {
background-color: var(--clr-3641);
color: #ffffff;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-3640);
 }
 }
a.MEC8 { color: var(--clr-3640);
&:hover { color: var(--clr-3639);}
 }
cite.MEC8{
color: #ffffff;
}
/* Light:9 */
.MES9 {
background-color: var(--clr-3642);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-3642);
 }
.MES10 {
background-color: var(--clr-3642);
 }
.MEC10 li {color: var(--clr-3641-flat);}
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-3643);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-3643);
 }
.MES12 {
background-color: var(--clr-3643);
 }
/* Header:13 */
.MES13 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-3641);
font-size: 16px;
min-height:60px;
@media #{$medium-up} {
min-height: 60px;};
@media #{$large-up} {
min-height: 100px;};
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-3642);
 }
.MES13 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-3641);
font-size: 16px;
min-height:60px;
@media #{$medium-up} {
min-height: 60px;};
@media #{$large-up} {
min-height: 100px;};
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-3642);
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: var(--clr-3640);
 }
 }
a.MEC13 { color: var(--clr-3640);
&:hover { color: var(--clr-3641);}
 }
cite.MEC13{
color: var(--clr-3641);
font-size: 16px;
}
/* home hover panel:14 */
.MES14 {
background-color: var(--clr-3644);
color: var(--clr-3639);
padding: 0 20px;

 }
.MES14 {
background-color: var(--clr-3644);
color: var(--clr-3639);
padding: 0 20px;

h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-3639);
 }
 }
a.MEC14 { color: var(--clr-3641);
&:hover { color: var(--clr-3641);}
 }
.MEC14 li {color: var(--clr-3641-flat);}
cite.MEC14{
color: var(--clr-3639);
}
/* :15 */
.MES15 {
background-color: var(--clr-3644);
color: var(--clr-3641);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-3639) transparent transparent transparent;
 }
.MES15 {
background-color: var(--clr-3644);
color: var(--clr-3641);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-3639) transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-3641);
 }
 }
.MEC15 li {color: var(--clr-3641-flat);}
cite.MEC15{
color: var(--clr-3641);
}
/* Hollaw:16 */
.MES16 {
color: var(--clr-3644);
&:hover { color: var(--clr-3644);}
border-width: 1px;
border-style: solid;
border-color: var(--clr-3644);
&:hover { border-color: var(--clr-3644); }
 }
/* Slider:17 */
.MES17 {
& .slider-arrow {font-size: 40px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{color: #ffffff;
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 22;
border-radius: 50%;
border-style:solid;
border-width:16px;
@media #{$medium-up} {
border-width: 16px;
};
@media #{$large-up} {
border-width: 16px;};
&:hover{border-color: 22;}
background-color: 21;
background-clip: padding-box;
&:hover {background-color: 2;}
width:8px;
height:8px;
@media #{$medium-up} {
width:8px;
height:8px;
};
@media #{$large-up} {
width:8px;
height:8px;
};
&:hover{background-color: 2;}
}}
& .slick-dots{bottom:2px;
@media #{$medium-up} {
bottom: 5px;};
} }
/* Contents:18 */
.MES18 {
background-color: var(--clr-3644);
&:hover, &.hover { background-color: var(--clr-3644);}
color: var(--clr-3641);
 }
.MES18 {
background-color: var(--clr-3644);
&:hover, &.hover { background-color: var(--clr-3644);}
color: var(--clr-3641);
 }
a.MEC18 { color: var(--clr-3640);
&:hover { color: var(--clr-3639);}
 }
.MEC18 li {color: var(--clr-3641-flat);}
cite.MEC18{
color: var(--clr-3641);
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: var(--clr-3644);
 }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-3639);
color: #ffffff;
font-size: 12.8px;
 }
.MES20 {
background-color: var(--clr-3639);
color: #ffffff;
font-size: 12.8px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
a.MEC20 { color: var(--clr-3642);
&:hover { color: var(--clr-3649);}
 }
cite.MEC20{
color: #ffffff;
font-size: 12.8px;
}
/* News grid:21 */
.MES21 {
color: var(--clr-3641);
border-width: 1px;
border-style: solid;
border-color: #ffffff var(--clr-3642);
 }
.MES21 {
color: var(--clr-3641);
border-width: 1px;
border-style: solid;
border-color: #ffffff var(--clr-3642);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-3640);
 }
 }
cite.MEC21{
color: var(--clr-3641);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-3642);
}
& > summary{padding: 10px;}

& > article{padding: 15px;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3644) transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-3640);
}
color: #ffffff;
& > article { color: #ffffff;
 }
& > summary{padding: 10px;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-3644) transparent;
} }
/* background image:26 */
.MES26 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://qcpe.com.au/img/2308/29');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES26 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://qcpe.com.au/img/2308/29');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
/* 50px:27 */
.MES27 {
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 50px;

}
 }
/* line Seperator:28 */
.MES28 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-3643);}
& > hr {border-top-style: dotted;}
 }
/* CTA Button:29 */
.MES29 {
background-color: var(--clr-3646);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 20.8px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 27.2px;
};
padding: 20px;

 }
/* Top Menu:30 */
nav.me-Menu.MES30 {
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: var(--clr-3647);
font-size: 17.6px;
}
 &:hover > a.MEC30{color: var(--clr-3640);
}
 }
&.horizontal > .menu-item.MEC30 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC30:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-3648-flat);}}
&.vertical .menu-item.MEC30:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-3648-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC30{background-color: var(--clr-3648);}

}
}
 }
/* :31 */
.MES31 {
background-color: var(--clr-3640);
&:hover, &.hover { background-color: var(--clr-3649);}
color: #ffffff;
border-radius: 10px 10px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES31 {
background-color: var(--clr-3640);
&:hover, &.hover { background-color: var(--clr-3649);}
color: #ffffff;
border-radius: 10px 10px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
cite.MEC31{
color: #ffffff;
}
/* Header Phone:32 */
.MES32 {
color: var(--clr-3639);
font-size: 16px;
@media #{$large-up} {
font-size: 35.2px;
};
 }
.MES32 {
color: var(--clr-3639);
font-size: 16px;
@media #{$large-up} {
font-size: 35.2px;
};
 }
a.MEC32 { color: var(--clr-3639);
&:hover { color: var(--clr-3640);}
 }
cite.MEC32{
color: var(--clr-3639);
font-size: 16px;
@media #{$large-up} {
font-size: 35.2px;
};
}
/* Header Phone Responsive:33 */
.MES33 {
color: var(--clr-3640);
font-size: 35.2px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 35.2px;
};
 }
.MES33 {
color: var(--clr-3640);
font-size: 35.2px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 35.2px;
};
 }
a.MEC33 { color: var(--clr-3640);
&:hover { color: var(--clr-3639);}
 }
cite.MEC33{
color: var(--clr-3640);
font-size: 35.2px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 35.2px;
};
}
/* Email Panel:34 */
.MES34 {
color: var(--clr-3639);
font-size: 16px;
 }
.MES34 {
color: var(--clr-3639);
font-size: 16px;
 }
a.MEC34 { color: var(--clr-3640);
&:hover { color: var(--clr-3649);}
 }
cite.MEC34{
color: var(--clr-3639);
font-size: 16px;
}
/* CTA Button - Orange:35 */
.MES35 {
background-color: var(--clr-3640);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 20.8px;
border-radius: 10px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* :36 */
nav.me-Menu.MES36 {
& .menu-item.MEC36, & .menu-item.MEC36 > div.MEC36{ & > a.MEC36{}
  }
&.horizontal > .menu-item.MEC36 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC36 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC36 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC36 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
& .menu-item.MEC36, & .menu-item.MEC36 > div.MEC36{ & > a.MEC36{}
 &:hover > a.MEC36{color: 25;
}
 }

}
}
 }
/* Top Menu Orange:37 */
nav.me-Menu.MES37 {
& .menu-item.MEC37, & .menu-item.MEC37 > div.MEC37{ & > a.MEC37{color: var(--clr-3640);
text-transform: uppercase;
}
 &:hover > a.MEC37{color: var(--clr-3639);
}
 }
&.horizontal > .menu-item.MEC37 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC37 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC37 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC37 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 20px;}

& .sub-menu .menu-item a{padding: 10px 20px;}


& > .menu-item.MEC37.active { & > a{ color: var(--clr-3641);}
 }
&.horizontal .menu-item.MEC37:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-3640-flat);}}
&.vertical .menu-item.MEC37:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-3640-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC37{background-color: var(--clr-3640); &:hover {background-color: 25;}
}
& .menu-item.MEC37, & .menu-item.MEC37 > div.MEC37{ & > a.MEC37{color: #ffffff;
font-size: 16px;
}
 &:hover > a.MEC37{color: 1;
}
 }

}
}
 }
/* Header Scroll:38 */
.MES38 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-3642);}
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-3642);
 }
.MES38 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-3642);}
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-3642);
 }
/* Responsive menu:39 */
nav.responsive-menu {
.menu-item.MEC39{background-color: var(--clr-3640);}
& .menu-item.MEC39, & .menu-item.MEC39 > div.menu-item-wrap{ & > a.MEC39, & > i{color: #ffffff;
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC39 { border:0;
border-color: #ffffff;border-style: solid;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* Content Panel round Corners white:40 */
.MES40 {
background-color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 25px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES40 {
background-color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 25px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Small Panel round Corners white:41 */
.MES41 {
background-color: #ffffff;
color: var(--clr-3639);
border-radius: 15px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
border-width: 2px;
border-style: solid;
border-color: var(--clr-3642);
 }
.MES41 {
background-color: #ffffff;
color: var(--clr-3639);
border-radius: 15px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
border-width: 2px;
border-style: solid;
border-color: var(--clr-3642);
 }
cite.MEC41{
color: var(--clr-3639);
}
/* CTA Button - Dark:43 */
.MES43 {
background-color: var(--clr-3639);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 20.8px;
border-radius: 10px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Grey icon footer:44 */
.MES44 {
background-color: var(--clr-3641);
&:hover, &.hover { background-color: var(--clr-3643);}
color: #ffffff;
border-radius: 0 0 10px 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES44 {
background-color: var(--clr-3641);
&:hover, &.hover { background-color: var(--clr-3643);}
color: #ffffff;
border-radius: 0 0 10px 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: #ffffff;
 }
 }
cite.MEC44{
color: #ffffff;
}
/* Slider white arrow:45 */
.MES45 {
& .slider-arrow {color: var(--clr-3648);
font-size: 50px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 70px;
};
}& .slider-arrow:hover{color: var(--clr-3649);
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 20;
border-radius: 50%;
border-style:solid;
border-width:4px;
@media #{$medium-up} {
border-width: 4px;
};
@media #{$large-up} {
border-width: 4px;};
&:hover{border-color: 22;}
background-color: 21;
background-clip: padding-box;
&:hover {background-color: 2;}
width:8px;
height:8px;
@media #{$medium-up} {
width:8px;
height:8px;
};
@media #{$large-up} {
width:8px;
height:8px;
};
&:hover{background-color: 2;}
}}
& .slick-dots{bottom:20px;
@media #{$large-up} {
bottom: 30px;};
} }
/* Content2 Panel round Corners white:46 */
.MES46 {
background-color: var(--clr-3648);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 25px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES46 {
background-color: var(--clr-3648);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 25px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
